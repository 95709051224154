import React from 'react';

import { ScContainer } from '../components/container/styled';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { InfoSectionCookie } from '../sections/CookiePolicy/InfoSectionCookie';
import { ScLastUpdateWrapper } from '../sections/CookiePolicy/styled';
import { InformationWeCollect } from '../sections/PrivacyPolicy/InformationWeCollect';
import {SEO} from "../components/Seo/Seo";

const cards = [
	{
		title: 'Personal information we collect',
		text: [
			`<p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. For more information on cookie collection please see our cookies policy. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information."<br/><br/> We collect Device Information using the following technologies:</p>
        <ul>
            <li>"Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="https://www.allaboutcookies.org" target="_blank" style="color: #28272F; word-break: break-all;">https://www.allaboutcookies.org</a></li>
            <li>"Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
            <li>"Web beacons," "tags," and "pixels" are electronic files used to record information about how you browse the Site.</li>
        </ul>
        <p>
            Additionally when you submit a request for a quote through the Site, we collect certain information from you, including your name, postcode, email address, and phone number. We refer to this information as "Customer Information."<br/>We will also collect information on your bike fit measurements and purchasing preferences, as required to fulfil your enquiries.<br/>When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device Information and Customer Information.
        </p>
   `,
		],
	},
	{
		title: 'How do we use your personal information?',
		text: [
			`<p>We use personal data in response to voucher requests in order to be able to issue vouchers under the cycle to work scheme, and process invoices.<br/><br/>We use the Customer Information that we collect generally to respond to any bike fit and purchase enquiries placed through the Site (including obtaining quotes, calculating the correct fit, arranging for shipping, and providing you with invoices and/or order confirmations).<br/><br/>Additionally, we may use this Customer Information to:</p>
         <ul>
            <li>Communicate with you;</li>
            <li>Screen our orders for potential risk or fraud;</li>
            <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
         </ul>
         <p>
            We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimise our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).
         </p>
         <br/>
         <p>
            We may also share details in aggregate, relating to the use of the site, with industry partners (without personally identifiable information) for the purposes of market research.
         </p>
    `,
		],
	},
	{
		title: 'Sharing your personal information',
		text: [
			`<p>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we will share your fit details with selected bike retailers in order for them to provide quotes. We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" style="color: #28272F; word-break: break-all;">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" style="color: #28272F; word-break: break-all;">https://tools.google.com/dlpage/gaoptout</a>.</p>
            <br/>
            <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
            `,
		],
	},
	{
		title: 'Behavioural advertising',
		text: [
			`<p>
                As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative's ("NAI") educational page at <a href="https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" target="_blank" style="color: #28272F; word-break: break-all;">https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
            </p>`,
		],
	},
	{
		title: 'Do not track',
		text: [
			`<p>
                Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser.
            </p>`,
		],
	},
	{
		title: 'Your rights',
		text: [
			`<p>
                If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, restricted or deleted. If you would like to exercise this right, please contact us through the contact information below.
            </p>
            <br/>
            <p>
                Additionally, if you are a European resident we note that we are processing your information in order to fulfil contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
            </p>
            <br/>
            <p>
                If you have given us separate consent to data processing, you may revoke this consent at any time. Such revocation does not affect the lawfulness of the processing that was carried out on the basis of the consent until revocation. If you believe that a processing of personal data violates the provisions of Data Protection Law, you have the right to lodge a complaint with a supervisory authority.
            </p>
            `,
		],
	},
	{
		title: 'Data retention',
		text: [
			`<p>
                The Company shall not keep personal data for any longer than is necessary in light of the purpose or purposes for which that personal data was originally collected, held, and processed . When you request a voucher we will keep the request to match with our invoicing. However, all reasonable steps will be taken to erase or otherwise dispose of personal data when it is no longer required.
            </p>
            <br/>
            <p>
                When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
            </p>
            `,
		],
	},
	{
		title: 'Changes',
		text: [
			`<p>
                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
             </p>`,
		],
	},
];

const PrivacyPolicy = () => {
	return (
		<MainLayout>
			<ScPage>
				<div style={{ marginTop: '60px' }}></div>
				<InfoSectionCookie
					title="Gogeta Privacy Policy"
					text={`This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make an enquiry on gogeta.bike (the "Site") under Data Protection Law. “Data Protection Law” means all applicable legislation in force from time to time in the United Kingdom applicable to data protection and privacy including, but not limited to, the UK GDPR, the Data Protection Act 2018 (and regulations made thereunder), and the Privacy and Electronic Communications Regulations 2003 as amended, and any successor legislation.`}
				/>
				<InformationWeCollect cards={cards} />
				<InfoSectionCookie
					isSmallTitle
					title="Contact us"
					text={`<p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto: hello@gogeta.com" style="color: #28272F; font-weight: 600">hello@gogeta.com</a> or by mail using the details provided below: <br/><br/> <b>Gogeta, Birchin Court, 20 Birchin Lane, London EC3V 9DU.</b></p>`}
				/>
				<ScContainer>
					<ScLastUpdateWrapper>
						<strong>Last updated: 04/08/2023</strong>
					</ScLastUpdateWrapper>
				</ScContainer>
			</ScPage>
		</MainLayout>
	);
};

export default PrivacyPolicy;

export const Head = () => (
	<SEO
		title="Privacy Policy | How Gogeta Protects Your Data"
		description="Learn how Gogeta safeguards your personal data with our comprehensive privacy policy. Understand your rights and our commitment to data protection and transparency."
	/>
)
